import React, { useState } from 'react';
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import { Box, FormControl, FormLabel, Input, Button, Heading, Image, Text } from '@chakra-ui/react';
import API from '../../../../../../Services/API';
import Styles from '../preview.module.css';

const SectionOne = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [heading, setHeading] = useState(props.label);
    const [description, setDescription] = useState(props.sublabel);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const sectionImageUpload = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    };

    const saveContent = () => {
        setIsLoading(true);
        const formData = new FormData();
        if (file) {
            formData.append('banner', file);
        }
        if (description !== props.sublabel) {
            formData.append('sublabel', description);
        }
        formData.append('label', heading);
        formData.append('id', parseInt(props.id));
        formData.append('action', parseInt(props.pillarId));

        API.put(`/impact-story`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then((response) => {
                props.getUpdatedTitle(heading);
                props.getUpdated();
                setIsLoading(false);
                setSuccessMessage('Changes updated successfully.');
            })
            .catch((error) => {
                setErrorMessage(error?.response?.data?.sublabel?.[0]);
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {props.editable ? (
                <Box p="5px">
                    <FormControl border="1px solid #cccccc" p="10px" borderRadius="10px" mb="15px">
                        {errorMessage && <Box color="red">{errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()}</Box>}
                        {successMessage && <Box color="#00a0da">{successMessage}</Box>}
                        <FormLabel fontWeight="600" mb="5px">
                            Label
                        </FormLabel>
                        <Input value={heading} placeholder="Please provide label" mb="5px" onChange={(e) => setHeading(e.target.value)} />
                        <FormLabel fontWeight="600" mb="5px">
                            Section Image
                        </FormLabel>
                        <Input type="file" title="mainSection" onChange={sectionImageUpload} padding="4px" mb="5px" />
                        {preview && <Image src={preview} alt="Image Preview" mb="5px" />}
                        <FormLabel fontWeight="600" mb="5px">
                            Profile Name
                        </FormLabel>
                        <Input value={description} placeholder="Please provide sublabel" mb="5px" onChange={(e) => setDescription(e.target.value)} />
                        <Button className="buttonPrimary" title="mainsection" onClick={saveContent} mt="10px" float="right">
                            Save
                        </Button>
                        <Box className="clearfix"></Box>
                    </FormControl>
                </Box>
            ) : (
                <>
                    <Heading className={Styles.leftGridHeading}>{props.label}</Heading>
                    <Image className={Styles.profileImage} src={props.banner} alt={props.sublabel} crossOrigin="anonymous" />
                    <Text as="p" className={Styles.profileName}>
                        {props.sublabel}
                    </Text>
                </>
            )}
        </>
    );
};

export default SectionOne;
