import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import $ from 'jquery'
import API from '../../../../Services/API';
import { Link, useParams } from 'react-router-dom'
import Styles from './viewmetric.module.css'
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image, FormControl, FormLabel} from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
import Breadcrumb from '../../../../Components/Widgets/BreadCrumbs/BreadCrumbs'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import Aspirations from './Aspirations/Aspirations';
import MetrData from './MetricData/MetricData'
import MetricSettings from './MetricSettings/MetricSettings';
import { EditContext } from "../../../../Context/BreadcrumbsContext";



const ViewMetric = () => {
    const token = window.localStorage.getItem("accessToken")
    const [permissionType, setPermissionType] = useState(null)
    const [isLoading, setIsLoading]  = useState(true)
    const [editable, setEditable] = useState();
    const [action, setAction] = useState();
    const {metricId} = useParams();
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    const [metric, setMetric] = useState();
    const [aspirations, setAspirations] = useState()
    const [metricData, setMetricData] = useState()
    const {edit, setEdit } = useContext(EditContext);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        setIsLoading(true)
        API.get(`/user-management`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          setEmail(response.data.email)
        }).catch(error=>{
          setIsLoading(false)
        })
        API.get(`/get-metric?metric_id=${metricId}`)
        .then((response) => {
            setMetric(response.data[0])
            API.get(`/metric-target?metric_id=${metricId}`)
            .then(response1 => {
                setAspirations(response1.data)
                API.get(`/metric-data?metric_id=${metricId}`)
                .then(response=>{
                    setMetricData(response.data)
                    setIsLoading(false)
                }).catch(error=>{
                    setIsLoading(false)
                })
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
        const windowLocation = window.location.pathname;
        const pathParts = windowLocation.split('/');
        const firstExtractedId = pathParts[2];
        API.get(`/get-specific-modules?metric_id=${firstExtractedId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response => {
          const permission = response?.data?.permission;
          setPermissionType(permission);
        });
    },[metricId])

    const geteditStatus = (isEdit) =>{
        setEditable(isEdit)
    }

    const getUpdatedAspiration = (updatedAspiration) => {
        setAspirations(updatedAspiration)
    }

    const getUpdatedMetricData = (updatedAspiration) => {
        setMetricData(updatedAspiration)
    }

    const updateName = () =>{
        setIsLoading(true)
        const formData = new FormData()
        formData.append("name", $('#changeMetricName').val())
        formData.append('description', $("#description").val())
        formData.append("id", metric && metric.id)

        API.put(`/metric`, formData, {
            headers: {
            'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            API.get(`/get-metric?metric_id=${metricId}`)
            .then((response) => {
                setMetric(response.data[0])
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const filteredMetricData = (metricData) =>{
        setMetricData(metricData)
    }

    return (
        <>
            { 
                isLoading ? <LoaderSpinner />: null
            }
            <Breadcrumb geteditStatus={geteditStatus} title={metric && metric.name} type='metric' pillarId={metric && metric.id} />
            {
                edit && 
                (email === 'jasekhon@cisco.com' || 
                    email === 'justin@purposefy.tech' || 
                    email === 'nalin@cisco.com' || 
                    email === 'brsibley@cisco.com'
                ) ?
                    <Box mb={'15px'}>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}> Change Metric Name </FormLabel>
                            <Input placeholder='Please provide Metric Name' defaultValue={metric && metric.name} id={'changeMetricName'} />

                            <FormLabel fontWeight={'bold'} mt={'10px'}> Change Metric Description </FormLabel>
                            <JoditEditor
                                id={"description"}
                                ref={editor}
                                value={metric && metric.description}
                                tabIndex={1} // tabIndex of textarea
                                onChange={newContent => {}}
                            />

                            <Button className='buttonPrimary' mt={'10px'} onClick={updateName}> Save </Button>
                        </FormControl>
                    </Box> 
                : <Text> {metric && metric.description != null  ? parse(metric && metric.description) : ''} </Text>
            }
            <Card>
                <CardBody>
                    <Tabs size='md' variant='enclosed'>
                        <TabList>
                            <Tab  className={Styles.tabsGroup}
                                _selected={{
                                    color: "#00a0da",
                                    borderBottom: "2px solid #00a0da",
                                }}
                            >Aspirations</Tab>
                            <Tab
                                className={Styles.tabsGroup}
                                _selected={{
                                    color: "#00a0da",
                                    borderBottom: "2px solid #00a0da",
                                }}
                            >Metric Data</Tab>
                            {
                                permissionType !== 'viewer' ? 
                                <Tab className={Styles.tabsGroup}
                                    _selected={{
                                        color: "#00a0da",
                                        borderBottom: "2px solid #00a0da",
                                    }}
                                > Metric Settings </Tab> : null
                            }
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Aspirations permissionType={permissionType} getUpdatedAspiration={getUpdatedAspiration} editable={edit} aspirations={aspirations && aspirations} />
                            </TabPanel>
                            <TabPanel>
                                <MetrData permissionType={permissionType} editable={edit} title={metric && metric.name} getUpdatedMetricData={getUpdatedMetricData} metricData={metricData && metricData} filteredMetricData={filteredMetricData} />
                            </TabPanel>
                            <TabPanel>
                                <MetricSettings editable={edit} metric={metric && metric} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </CardBody>
            </Card>

            
        </>
    )
}

export default ViewMetric






