import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './overview.module.css'
import $ from 'jquery'
import API from '../../../../../../Services/API';
import { Link, useParams } from 'react-router-dom'
import {Card, CardHeader, CardBody, Heading, Grid, GridItem, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image, FormControl, FormLabel} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const Overview = (props) => {
  const token = window.localStorage.getItem("accessToken")
  

  const {actionid} = useParams();
  const [isLoading, setIsLoading]  = useState(true)

  const [actionTeamSharepointName, setActionTeamSharepointName] = useState('');
  const [actionTeamSharepointUrl, setActionTeamSharepointUrl] = useState('');
  const [ciscoFacingSharepointName, setCiscoFacingSharepointName] = useState('');
  const [ciscoFacingSharepointUrl, setCiscoFacingSharepointUrl] = useState('');
  const [publicFacingSharepointName, setPublicFacingSharepointName] = useState('');
  const [publicFacingSharepointUrl, setPublicFacingSharepointUrl] = useState('');
  const [errors, setErrors] = useState({});

  const [actionErrors, setActionErrors] = useState({});
  const [ciscoErrors, setCiscoErrors] = useState({});
  const [publicErrors, setPublicErrors] = useState({});

  const [sharePointData, setSharePointData] = useState(null);

  const [createdMessage, setCreatedMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [actionId, setActionId] = useState(null)
  

  useEffect(() => {
    setIsLoading(true)
    API.get(`/pillar?module_id=${actionid}`)
    .then((response) => {
      setActionId(response.data.id)
      API.get(`/share-point?action_id=${response.data.id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        
        if(response.data.length > 0){
          setSharePointData(response.data[0])
          setActionTeamSharepointName(response.data[0].action_team_sharepointname)
          setActionTeamSharepointUrl(response.data[0].action_team_sharepointurl)
          setCiscoFacingSharepointName(response.data[0].cisco_facing_sharepointname)
          setCiscoFacingSharepointUrl(response.data[0].cisco_facing_sharepointurl)
          setPublicFacingSharepointName(response.data[0].public_facing_sharepointname)
          setPublicFacingSharepointUrl(response.data[0].public_facing_sharepointurl)
        }
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })  

    
  },[actionId])

  const addSharePointData = (event) =>{
    setIsLoading(true)
    const body = {
      "action_team_sharepointname": null,
      "action_team_sharepointurl": null,
      "cisco_facing_sharepointname": null,
      "cisco_facing_sharepointurl": null,
      "public_facing_sharepointname": null,
      "public_facing_sharepointurl": null,
      "action": props.actionId,
    }
    API.post(`/share-point`,body, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      API.get(`/share-point?action_id=${actionId}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        setSharePointData(response.data[0])
        setIsLoading(false)
        setCreatedMessage(true)
      }).catch(error=>{
        setIsLoading(false)
      })
    }).catch(error=>{
      setIsLoading(false)
    })
  }


  const actionValidate = () => {
    const newErrors = {};

    if (!actionTeamSharepointName) {
      newErrors.actionTeamSharepointName = 'Action Team Sharepoint name is required';
    }

    if (!actionTeamSharepointUrl) {
      newErrors.actionTeamSharepointUrl = 'Valid Action Team Sharepoint URL is required';
    }
    setActionErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const ciscoValidate = () => {
    const newErrors = {};

    if (!ciscoFacingSharepointName) {
      newErrors.ciscoFacingSharepointName = 'Purposefy Facing Sharepoint name is required';
    }

    if (!ciscoFacingSharepointUrl) {
      newErrors.ciscoFacingSharepointUrl = 'Valid Purposefy Facing Sharepoint URL is required';
    }
    setCiscoErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const publicValidate = () => {
    const newErrors = {};

    if (!publicFacingSharepointName) {
      newErrors.publicFacingSharepointName = 'Public Facing Sharepoint name is required';
    }

    if (!publicFacingSharepointUrl) {
      newErrors.publicFacingSharepointUrl = 'Valid Public Facing Sharepoint URL is required';
    }

    setPublicErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const saveUpdate = (name) => {
    if(name === 'action'){
      if(actionValidate()){
        setIsLoading(true)
        const body = {
          "action_team_sharepointname": $('#actionLinkTitle').val(),
          "action_team_sharepointurl": $('#actionLinkUrl').val(),
          "action": props.actionId,
          "id": sharePointData && sharePointData.id
        }
        API.put(`/share-point`,body, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          API.get(`/share-point?action_id=${actionId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then((response) => {
            setSharePointData(response.data[0])
            setIsLoading(false)
            setSuccessMessage(true)
          }).catch(error=>{
            setIsLoading(false)
          })
        }).catch(error=>{
          setIsLoading(false)
        })
      }
     }else if(name === 'cisco'){
      if(ciscoValidate()){
        setIsLoading(true)
        const body = {
          "cisco_facing_sharepointname": $('#ciscoLinkTitle').val(),
          "cisco_facing_sharepointurl": $('#ciscoLinkUrl').val(),
          "action": props.actionId,
          "id": sharePointData && sharePointData.id
        }
        API.put(`/share-point`,body, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          API.get(`/share-point?action_id=${actionId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then((response) => {
            setSharePointData(response.data[0])
            setIsLoading(false)
            setSuccessMessage(true)
          }).catch(error=>{
            setIsLoading(false)
          })
        }).catch(error=>{
          setIsLoading(false)
        })
      }
     }else{
      if(publicValidate()){
        setIsLoading(true)
        const body = {
          "public_facing_sharepointname": $('#publicLinkTitle').val(),
          "public_facing_sharepointurl": $('#publicLinkUrl').val(),
          "action": props.actionId,
          "id": sharePointData && sharePointData.id
        }
        API.put(`/share-point`,body, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          API.get(`/share-point?action_id=${actionId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then((response) => {
            setSharePointData(response.data[0])
            setIsLoading(false)
            setSuccessMessage(true)
          }).catch(error=>{
            setIsLoading(false)
          })
        }).catch(error=>{
          setIsLoading(false)
        })
      }
    }
  };

  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {successMessage ? <Box className='colorBlue'> Changes updated successfully. </Box> : null}
      {createdMessage ? <Box className='colorBlue'> Sharepoint created  successfully </Box> : null}
      
      <Box>
        
          <>
            {
              sharePointData && sharePointData !== null ? 
              <Box>
                {
                  props.editable ? 
                    <>
                      <Card mt={'15px'}>
                        <CardBody>
                          <Heading as={'h3'} className={Styles.heading} mt={'15px'}> Action Team SharePoint </Heading>
                          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                            <GridItem w='100%'>
                              <FormControl mb={'10px'} isInvalid={actionErrors.actionTeamSharepointName}>
                                <FormLabel mb={'10px'} fontWeight={'bold'}> Link title </FormLabel>
                                <Input 
                                  defaultValue={actionTeamSharepointName} 
                                  placeholder='Link title' 
                                  mb={'10px'} 
                                  id='actionLinkTitle' 
                                  onChange={(e) => setActionTeamSharepointName(e.target.value)}
                                />
                                {actionErrors.actionTeamSharepointName && (
                                  <Text color='red.500'>{actionErrors.actionTeamSharepointName}</Text>
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem w='100%'>
                              <FormControl mb={'10px'} isInvalid={actionErrors.actionTeamSharepointUrl}>
                                <FormLabel mb={'10px'} fontWeight={'bold'}> Link </FormLabel>
                                <Input 
                                  defaultValue={actionTeamSharepointUrl} 
                                  placeholder='https://example.com' 
                                  mb={'10px'} 
                                  id='actionLinkUrl'
                                  onChange={(e) => setActionTeamSharepointUrl(e.target.value)}
                                />
                                {actionErrors.actionTeamSharepointUrl && (
                                  <Text color='red.500'>{actionErrors.actionTeamSharepointUrl}</Text>
                                )}
                              </FormControl>
                            </GridItem>
                          </Grid>
                          <Button 
                            className='buttonPrimary' 
                            float={'right'} 
                            name={'action'} 
                            onClick={() => saveUpdate('action')}
                          > Save </Button>
                        </CardBody> 
                      </Card>
                    </>
                  : 
                  <>
                    <Link className='colorBlue displayBlock' to={sharePointData && sharePointData.action_team_sharepointurl} target="_blank" rel="noopener noreferrer"> 
                      {sharePointData && sharePointData.action_team_sharepointname} {sharePointData && sharePointData.action_team_sharepointurl !== null ? <ExternalLinkIcon mx='2px' /> : null } 
                    </Link>
                  </>
                }
                {
                  props.editable ? 
                    <>
                      <Card mt={'15px'}>
                        <CardBody>
                          <Heading as={'h3'} className={Styles.heading}  mt={'15px'}> Purposefy Facing SharePoint </Heading>
                          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                            <GridItem w='100%'>
                              <FormControl mb={'10px'} isInvalid={ciscoErrors.ciscoFacingSharepointName}>
                                <FormLabel mb={'10px'} fontWeight={'bold'}> Link title </FormLabel>
                                <Input 
                                  defaultValue={ciscoFacingSharepointName} 
                                  placeholder='Link title' id='ciscoLinkTitle' 
                                  mb={'10px'}
                                  onChange={(e) => setCiscoFacingSharepointName(e.target.value)}
                                />
                                {ciscoErrors.ciscoFacingSharepointName && (
                                  <Text color='red.500'>{ciscoErrors.ciscoFacingSharepointName}</Text>
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem w='100%'>
                              <FormControl mb={'10px'} isInvalid={ciscoErrors.ciscoFacingSharepointUrl}>
                                <FormLabel mb={'10px'} fontWeight={'bold'}> Link </FormLabel>
                                <Input 
                                  defaultValue={ciscoFacingSharepointUrl} 
                                  placeholder='https://example.com' 
                                  id='ciscoLinkUrl' 
                                  mb={'10px'}
                                  onChange={(e) => setCiscoFacingSharepointUrl(e.target.value)}
                                />
                                {ciscoErrors.ciscoFacingSharepointUrl && (
                                  <Text color='red.500'>{ciscoErrors.ciscoFacingSharepointUrl}</Text>
                                )}
                              </FormControl>
                            </GridItem>
                          </Grid>
                          <Button onClick={() => saveUpdate('cisco')} className='buttonPrimary' float={'right'} name={'cisco'}> Save </Button>
                        </CardBody> 
                      </Card>
                    </>
                  :
                  <>
                    <Link className='colorBlue displayBlock' to={sharePointData && sharePointData.cisco_facing_sharepointurl} target="_blank" rel="noopener noreferrer"> 
                      {sharePointData && sharePointData.cisco_facing_sharepointname} {sharePointData && sharePointData.cisco_facing_sharepointurl !== null ? <ExternalLinkIcon mx='2px' /> : null } 
                    </Link>
                  </>
                }
                
                {
                  props.editable ? 
                    <>
                      <Card mt={'15px'}>
                        <CardBody>
                          <Heading as={'h3'} className={Styles.heading}> Public Facing Site </Heading>
                          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                            <GridItem w='100%'>
                              <FormControl mb={'10px'} isInvalid={publicErrors.publicFacingSharepointName}>
                                <FormLabel mb={'10px'} fontWeight={'bold'}> Link title </FormLabel>
                                <Input 
                                  defaultValue={publicFacingSharepointName} 
                                  placeholder='Link title' id='publicLinkTitle' mb={'10px'}
                                  onChange={(e) => setPublicFacingSharepointName(e.target.value)}
                                />
                                {publicErrors.publicFacingSharepointName && (
                                  <Text color='red.500'>{publicErrors.publicFacingSharepointName}</Text>
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem w='100%'>
                              <FormControl mb={'10px'} isInvalid={publicErrors.publicFacingSharepointUrl}>
                                <FormLabel mb={'10px'} fontWeight={'bold'}> Link </FormLabel>
                                <Input 
                                  defaultValue={publicFacingSharepointUrl} 
                                  placeholder='https://example.com' 
                                  id='publicLinkUrl' 
                                  mb={'10px'}
                                  onChange={(e) => setPublicFacingSharepointUrl(e.target.value)}
                                />
                                {publicErrors.publicFacingSharepointUrl && (
                                  <Text color='red.500'>{publicErrors.publicFacingSharepointUrl}</Text>
                                )}
                              </FormControl>
                            </GridItem>
                          </Grid>
                          <Button onClick={() => saveUpdate('public')} className='buttonPrimary' float={'right'} name={'public'}> Save </Button>
                        </CardBody> 
                      </Card>
                    </>
                  : 
                  <>
                    <Link className='colorBlue displayBlock' to={sharePointData && sharePointData.public_facing_sharepointurl} target="_blank" rel="noopener noreferrer"> 
                      {sharePointData && sharePointData.public_facing_sharepointname} {sharePointData && sharePointData.public_facing_sharepointurl !== null ? <ExternalLinkIcon mx='2px' /> : null } 
                    </Link>
                  </>
                }
              </Box>
              : 
              <>
                {
                  props.editable ? <Button className='defaultButton noPosition' mt={'0'} onClick={addSharePointData}> Add share point data </Button> : null
                }
              </>
            }
          </> 
        



        
      </Box>
    </>
  )
}

export default Overview