import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './Slider.module.css'
import API from '../../../../../../../Services/API';
import {Heading, Select, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

import FooterImg from '../../../../../../../../assets/images/cisco-white.png'

const SlideZero = (props) => {
  const [beforeDash, afterDash] = props.title.split(' - ');
  return (
    <>
      <Box id='pdf-content0' h="420px" className={Styles.sectionBg}>
        <Box className={Styles.titleSection}>
          <Heading as="h3">
            <Text className={Styles.beforeDash}>{beforeDash}</Text>            
            <Text className={Styles.afterDash}>{afterDash}</Text>
            <Text className={Styles.fiscal}> 
              {/* {props.firstFiscalYear && props.firstFiscalYear} - {props.firstFiscalQtr && props.firstFiscalQtr.split(' ')[1]}  */}
              {props.firstFiscalYear && props.firstFiscalYear} - 
              {props.firstFiscalQtr && typeof props.firstFiscalQtr === 'string' && props.firstFiscalQtr.split(' ')[1]}
            </Text>
          </Heading>
        </Box>
        <Box className={Styles.footer}>
          {/* <Image src={FooterImg} width={'60px'} float={'left'} alt={beforeDash} /> */}
          <Text as={'span'} className={Styles.copy}> © 2024 Purposefy and/or its affiliates. All rights reserved. Purposefy confidential </Text>
        </Box>
      </Box>
    </>
  )
}

export default SlideZero