import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from '../../executiveleadershipreviews.module.css'
import API from '../../../../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea, Table, Tr, Td } from '@chakra-ui/react'
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget';

const SectionThree = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [profileImage, setProfileImage] = useState(null);
    const [heading, setHeading] = useState(props.label)
    const [description, setDescription] = useState(props.sublabel)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [infoMessage, setInfoMessage] = useState(null);

    function sectionImageUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Create a file reader to read the file and generate a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }
    const changeSubLabel = (event) =>{
        setDescription(event.target.value)
    }

    const saveAction = (event) =>{
        setIsLoading(true)
        setInfoMessage(null)
        setSuccessMessage(null)
        setErrorMessage(null)
        if((description !== props.description) || (file !== null) ){
            const formData = new FormData()
            if(file != null){
                formData.append('footerbanner'+props.index, file)
            }
            formData.append('footer'+props.index, description)
            formData.append('id', parseInt(event.target.id))
            formData.append('action', parseInt(props.pillarId))
            formData.append('approval_status','Ready to Submit')
            API.put(`/impact-story`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                API.get(`/impact-story?action=${props.pillarId}`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    props.getSecondSlideUpdate(response.data)
                    props.getslide2Update()
                    setSuccessMessage('Changes updated successfully.')
                    setIsLoading(false)
                }).catch(error=>{
                    setIsLoading(false)
                    setErrorMessage(error.response.data.msg)
                    props.getError(error.response.data.msg)
                })
            }).catch(error=>{
                setErrorMessage(error.response.data.msg)
                setIsLoading(false)
            })
        }else{
            setInfoMessage('No changes are made')
            setIsLoading(false)
        }
    }

    return (
        <>
            {
                isLoading ? <LoaderSpinner /> : null
            }
            {
                errorMessage !== null ? <ErrorWidget message={errorMessage} /> : null
            }
            {
                successMessage !== null ? <ErrorWidget message={successMessage} /> : null
            }
            {
                infoMessage !== null ? <ErrorWidget message={infoMessage} /> : null
            }
            <Box>
                {
                    props.editable ?
                    <FormControl border={'1px solid #cccccc'} p={'10px'} borderRadius={'10px'} mb={'15px'}>
                        <FormLabel fontWeight={'600'} mb={'5px'}> Upload Icon </FormLabel>
                        <Input type="file" title='footer1' onChange={sectionImageUpload} padding={"4px"} mb={'5px'} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Description </FormLabel>
                        <Textarea placeholder='Please provide description 1' defaultValue={props.description}  onChange={changeSubLabel}  />
                        <Button className='' title='footer1' onClick={saveAction} mt={'10px'} float={'right'} id={props.id} backgroundColor={'#ffffff'} color={'#00a0da'}> Save </Button>
                        <Box className='clearfix'></Box>
                    </FormControl> : 
                    <Box className={Styles.ftrMnContainer}>
                        <Flex align="center" id='middleContent'>
                            {
                                props.banner ? 
                                    <Image src={props.banner} boxSize="50px" crossOrigin="anonymous" alt="Description" border={'none'} /> 
                                :null
                            }
                            <Text fontSize="sm" mb={'0'}>{props?.description}</Text>
                        </Flex>
                    </Box>
                }
            </Box>
            
        </>
    )
}

export default SectionThree