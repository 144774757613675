import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import Styles from './allmetrics.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, Skeleton, SkeletonCircle, SkeletonText, Stack} from '@chakra-ui/react'
import AreaChartComponent from '../../../../../Components/Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import TabLoader from '../../../../../Components/Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../../../Components/Modules/NumberFormat/NumberFormat';
import DeleteMetric from '../DeleteMetric/DeleteMetric';
import PieChartComponent from '../../../../../Components/Modules/Charts/PieChartComponent';

const AllMetrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [allMetrics, setAllMetrics] = useState()
  const [filterStatus, setFilterStatus] = useState()
  const [pillarId, setPillarId] = useState()
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)
  const [opsMetrics, setOpsMetrics] = useState()
  const loopCardRef = useRef(null);
  const [matchedData, setMatchedData] = useState([]);


  useEffect(() => {
    setIsLoading(true)
    props.metricType(`allmetric`)
    props.emptyMetric()

    API.get(`/get-pillar-action`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      console.log(response.data)
      if(props.selectedOption?.name && props.selectedOption.name ==='year'){
        API.get(`/metric-filter?fiscal_year=${props.selectedOption?.value && props.selectedOption.value}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setOpsMetrics(response1.data)
          const matchedDataArray = response.data && response.data.filter(data1Obj =>
            response1.data && response1.data.some(dataObj => dataObj.action_id === data1Obj.id)
          );
          setMatchedData(matchedDataArray);
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }else if(props.selectedOption?.name && props.selectedOption.name ==='qtr'){
        API.get(`/metric-filter?fiscal_qtr=${props.selectedOption?.value && props.selectedOption.value}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setOpsMetrics(response1.data)
          const matchedDataArray = response.data && response.data.filter(data1Obj =>
            response1.data && response1.data.some(dataObj => dataObj.action_id === data1Obj.id)
          );
          setMatchedData(matchedDataArray);
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }else{
        API.get(`/all-metric-dashboard`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setOpsMetrics(response1.data)
          const matchedDataArray = response.data && response.data.filter(data1Obj =>
            response1.data && response1.data.some(dataObj => dataObj.action_id === data1Obj.id)
          );
          setMatchedData(matchedDataArray);
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }
    })
  },[])

  const currentYear = new Date().getFullYear();
  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };
  const getUpatedMetric = (metric) =>{
    setAllMetrics(metric)
    setDeleteMessage(true)
  }
  const isLoaderloading = (loding) =>{
    setNewLoaderLoading(loding)
  }

  const customSort = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    const numA = parseInt(nameA.match(/\d+/), 10);
    const numB = parseInt(nameB.match(/\d+/), 10);
    if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
      return numA - numB;
    }
    return nameA.localeCompare(nameB);
  };

  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {deleteMessage ? <Box className='colorBlue'> Deleted metric successfully </Box> : null}
      {
        props.isLoading || isLoading ? 
          <>
            <Stack>
              <Skeleton height='20px' />
            </Stack>
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'} className='loopCard' ref={loopCardRef}>
              <Skeleton height='200px' />
              <Skeleton height='200px' />
              <Skeleton height='200px' />
              <Skeleton height='200px' />
            </SimpleGrid>
          </>
        : 
        <>
          <Box>
            <PieChartComponent 
              metricData={props.filterStatus && props.filterStatus === 1 ? props.metric && props.metric : opsMetrics && opsMetrics} 
            />
          </Box>
          {
            props.filterStatus && props.filterStatus === 1 ?
              props.matchedData && props.matchedData.map(action  => 
                <>
                  <Box className='metricCard'>
                    <Heading textTransform='capitalize' className={Styles.cardTitleSub}>
                      {action.name}
                    </Heading>
                    {
                      props.filterStatus && props.filterStatus === 1 ?
                        <SimpleGrid className={'matchHide'} columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
                          {
                            props.metric && props.metric.sort(customSort).map(metric =>
                              metric.action_id === action.id ? 
                                <>
                                  {
                                    metric.name.includes("CCR GIG by focus area") || metric.name.includes("Breakdown by investment phase") || 
                                    metric.name.includes("Breakdown by Funding Round") || metric.name.includes("Global Impact Cash Grants") ? null :
                                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                                      <Box className='frontCard'>
                                        <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                                        {
                                          props.edit ? 
                                            <DeleteMetric 
                                              id={metric.id} 
                                              type={'operational'} 
                                              getUpatedMetric={getUpatedMetric}
                                              optionName={props.selectedOption.name && props.selectedOption.name}
                                              optionValue={props.selectedOption.value && props.selectedOption.value}
                                              filterStatus={props.filterStatus && props.filterStatus}
                                              isLoaderloading={isLoaderloading}
                                            /> 
                                          : null 
                                        }
                                        <Box className='clearfix'></Box>
                                        <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                                          <Box p={'10px'}>
                                            <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                              {metric.name}
                                            </Heading>
                                            <Box className='metricAlignment'>
                                              <Box className='metricCount'>
                                                <>
                                                  {
                                                    <> 
                                                      {
                                                        metric?.chart_value?.chart_top_value?.length > 0 ? 
                                                          <>
                                                            {
                                                              metric.chart_value.chart_top_value.map(item =>
                                                                item.value !== null ?
                                                                  <>
                                                                    { 
                                                                      metric.datatype === 'money' ? "$" : null
                                                                    }
                                                                    <NumberFormat number={item.value} />
                                                                    {
                                                                    metric.datatype === 'percent' ? "%" : null
                                                                  }
                                                                </> : null
                                                              )
                                                            }
                                                          </> 
                                                        : null
                                                      }
                                                    </>
                                                  }
                                                </>
                                              </Box>
                                              <AreaChartComponent chartData={metric?.chart_value?.result} />
                                            </Box>
                                          </Box>
                                        </Link>
                                      </Box>
                                      <Box className='backCard'>
                                        <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                                        <Box className='clearfix'></Box>
                                        {
                                          metric.description === '<p><br></p>' ? 
                                            <Box className='noDesc'> No description </Box>
                                          : 
                                          metric.description === null ? 'No description' : parse(metric.description)
                                        }
                                      </Box>
                                    </Box>
                                  }
                                </>
                              : null
                            )
                          }
                        </SimpleGrid>
                      : null
                    }
                  </Box>
                </>
              )
            : 
            matchedData && matchedData.sort(customSort).map(action  => 
              <>
                <Box className='metricCard'>
                  <Heading textTransform='capitalize' className={Styles.cardTitleSub}>
                    {action.name}
                  </Heading>
                  <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
                    {
                      opsMetrics && opsMetrics.map(metric => 
                        metric.action_id === action.id ?
                        <>
                        {
                          metric.name.includes("CCR GIG by focus area") || metric.name.includes("Breakdown by investment phase") || 
                          metric.name.includes("Breakdown by Funding Round") || metric.name.includes("Global Impact Cash Grants") ? null :
                          <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                      <Box className='frontCard'>
                        <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                        {props.edit ? <DeleteMetric id={metric.id} type={'operational'} getUpatedMetric={getUpatedMetric} isLoaderloading={isLoaderloading} /> : null }
                        <Box className='clearfix'></Box>
                        <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                          <Box p={'10px'}>
                            <Heading as='h5' size='sm' className={Styles.metricTitle + " headingHeight"}>
                                {metric.name}
                            </Heading>
                            <Box className='metricAlignment'>
                              <Box className='metricCount'>
                                <>
                                  {
                                    <> 
                                      {
                                        metric?.chart_value?.chart_top_value?.length > 0 ? 
                                          <>
                                            {
                                              metric.chart_value.chart_top_value.map(item =>
                                                item.value !== null ?
                                                  <>
                                                    { 
                                                      metric.datatype === 'money' ? "$" : null
                                                    }
                                                    <NumberFormat number={item.value} />
                                                    {
                                                    metric.datatype === 'percent' ? "%" : null
                                                  }
                                                </> : null
                                              )
                                            }
                                          </> 
                                        : null
                                      }
                                    </>
                                  }
                                </>
                              </Box>
                              <AreaChartComponent chartData={metric?.chart_value?.result} />
                            </Box>
                          </Box>
                        </Link>
                      </Box>
                      <Box className='backCard'>
                        <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                        <Box className='clearfix'></Box>
                        {
                          metric.description === '<p><br></p>' ? 
                            <Box className='noDesc'> No description </Box>
                          : 
                          metric.description === null ? 'No description' : parse(metric.description)
                        }
                      </Box>
                    </Box>
                        }
                    
                  </>:null
                      )
                    }
                  </SimpleGrid>
                </Box>
              </>
            )
          }
          {
            props.filterStatus === 1 ?
              props.matchedData && props.matchedData.length < 1 ?
                <Box className='successInfoNew'> {/* There are no metric(s) assigned to this action yet. */} </Box> 
              : null
            :matchedData && matchedData.length < 1 ?
              <Box className='successInfoNew'>
                {/* There are no metric(s) assigned to this action yet. */}
              </Box> 
            : null
          }
        </>
      }
    </>
  )
}

export default AllMetrics