import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../executiveleadershipreviews.module.css'
import API from '../../../../../../../../Services/API';
import { Button, Tooltip } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import { DeleteIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'

const DeleteAction = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()

    const updateStory = async () => {
        setIsLoading(true);

        // Creating FormData object to send data as multipart/form-data
        const formData = new FormData();
        formData.append("id", props.id);
        formData.append("visible", false);
        

        try {
            const response = await API.put(`/elr-dashboard`, 
                formData, // Sending formData as the request body
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data", // Important for FormData
                    },
                }
            );
            props.getUpdatedImpactStory(response.data)
            onClose()
            // Handle success response
        } catch (error) {
            console.error("Error updating impact story:", error.response?.data || error.message);
        } finally {
            setIsLoading(false);
            onClose(); // Close modal or take other actions
        }
    };

    return (
        <>
            <Button className='newDeleteButtonElr' onClick={onOpen}>
                <Tooltip label={`Delete Executive Leader Reporting : ${props.label} `} fontSize="md">
                    <DeleteIcon  onClick={onOpen} /> 
                </Tooltip>
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Delete Impact Story  </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete it? This action cannot be reversed.
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button  mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={updateStory}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteAction