import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from './preview.module.css'
import API from '../../../../../Services/API';
import {Heading, Select, Box, SimpleGrid, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import MetricInfo from '../../../../../Components/ProjectModule/Actions/ViewAction/ActionTabs/ExecutiveLeadershipReviews/SlideOne/MetricInfo/MetricInfo';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'

const Preview = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [selectedData, setSelectedData] = useState(null)
    const [elrFile, setElrFile] = useState(null);
    const [elrBanner, setElrBanner] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const editor = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        API.get(`/executive-level-review?id=${props.id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            console.log(response.data)
            setSelectedData(response?.data[0])
            
        }).catch(error=>{

        })
    },[props.pillar])

    const elrBannerUploadIn = async (e, elrId) => {
        setElrFile(null)
        const selectedFile = e.target.files[0];
        setElrFile(selectedFile);
    };

    const saveElrIn = (event) =>{
        const formData = new FormData()
        if(elrBanner != null){
            formData.append('banner', elrBanner)
        }
        formData.append('label', $("#elr_label"+event.target.id).val())
        formData.append('description', $("#elrDescription"+event.target.id).val())
        formData.append('action', parseInt(props.pillar))
        formData.append('id', event.target.id)
        
        API.put(`/executive-level-review`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            props.getUpdatedTitle($("#elr_label"+event.target.id).val())
            setIsLoading(false);
        }).catch(error=>{

        })
    }

    return (
        <>
            <Box border={'1px solid #e2e8f0'}>
                <Box>
                    <SimpleGrid spacing={4} columns={[3]} p={'15px'}>
                        <Card>
                            {
                                props.editable ? 
                                <>
                                    <CardHeader>
                                        <FormControl>
                                            <FormLabel fontWeight={'bold'}>Label</FormLabel>
                                            <Input type='text' id={'elr_label'+props.id} mb={'15px'} defaultValue={selectedData && selectedData.label}  />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight={'bold'}>Icon</FormLabel>
                                            <Input type="file" id={'bannerUpload'+props.id} onChange={(e) => elrBannerUploadIn(e, props.id)} padding={"4px"}/>
                                            <Image src={selectedData && selectedData.banner} width={'60px'} mt={'15px'}  alt={selectedData && selectedData.label} />
                                        </FormControl>
                                    </CardHeader>
                                    <CardBody>
                                        <FormLabel fontWeight={'bold'}>Description</FormLabel>
                                        <JoditEditor
                                            id={"elrDescription"+props.id}
                                            ref={editor}
                                            tabIndex={1} // tabIndex of textarea
                                            onChange={newContent => {}}
                                            value={selectedData && selectedData.description}
                                        />
                                    </CardBody> 
                                    <CardFooter>
                                        <Button className='buttonPrimary' onClick={saveElrIn} id={props.id}> Save </Button>
                                    </CardFooter>                                       
                                </> : 
                                <>
                                    <CardHeader>
                                        <Image src={selectedData && selectedData.banner} width={'60px'} alt={selectedData && selectedData.label} crossOrigin="anonymous" />
                                        <Heading size='md' className={Styles.boxHeading}> {selectedData && selectedData.label}</Heading>
                                    </CardHeader>
                                    <CardBody className='checkbody'>
                                        {selectedData && selectedData.description != null  ? parse(selectedData && selectedData.description) : ''}
                                    </CardBody>
                                </>
                            }
                        </Card>
                    </SimpleGrid>
                </Box>
            </Box>
        </>
    )
}

export default Preview