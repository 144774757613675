import React, { useEffect, useState } from 'react'
import API from '../../../../../Services/API'
import { useParams } from 'react-router-dom'
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading,
    Text
} from '@chakra-ui/react'


const MetricData = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const {yearid} = useParams();
    const {qtrid} = useParams();
    
    const {yearname} = useParams();
    const {qtrname} = useParams();
    
    const [metric, setMetric]  =useState()

    useEffect(()=>{
        document.documentElement.scrollTo(0, 0);
        console.log(props.type)
        if(props.type === 'year'){
            API.get(`/metric-data-period?fiscal_year=${yearid}`, {
                headers: {
                'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                setMetric(response.data)
            }).catch(error=>{

            })
        }else if(props.type === 'qtr'){
            API.get(`/metric-data-period?fiscal_quarter=${qtrid}`, {
                headers: {
                'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                setMetric(response.data)
            }).catch(error=>{

            })
        }
        
    },[])


    return (
        <>
            <Heading mt={'15px'} >
                <Text> Metric Data </Text>
            </Heading>
            <TableContainer mt={'15px'} backgroundColor={'#ffffff'}>
                <Table size='sm' colorScheme='teal' border="2px solid" borderColor="gray.200" rounded="md">
                    <Thead>
                        <Tr>
                            <Th color={'#ffffff'} p={'10px 15px'}>Value</Th>
                            <Th color={'#ffffff'} p={'10px 15px'}>Status</Th>
                            <Th color={'#ffffff'} p={'10px 15px'}>Geography	</Th>
                            <Th color={'#ffffff'} p={'10px 15px'}>Period - year</Th>
                            <Th color={'#ffffff'} p={'10px 15px'}>Period - Qtr</Th>
                            <Th color={'#ffffff'} p={'10px 15px'}>Metric</Th>
                            <Th color={'#ffffff'} p={'10px 15px'}>Approval</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            metric && metric.map(item=>
                                <Tr>
                                    <Td>{item.value}</Td>
                                    <Td textTransform={'capitalize'}>{item.metric_status}</Td>
                                    <Td textTransform={'capitalize'}>{item.geography_name}</Td>
                                    <Td>
                                        {item.fiscal_year_name}
                                    </Td>
                                    <Td>
                                        {item.fiscal_quarter_name}
                                    </Td>
                                    <Td>
                                        <Box width={'150px'} whiteSpace={'pre-wrap'}>
                                            {item.name}
                                        </Box>
                                    </Td>
                                    <Td textTransform={'capitalize'}>{item.approval_status}</Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MetricData